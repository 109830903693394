import {
  Box,
  Text,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  Layout,
  FeatureList,
  Button,
  CircularProgressBar,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Track from "./track.png";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const limitPlans: any = {
  basic: 15,
  lite: 100,
  pro: 2500,
  unlimited: 100000,
};

const CONTENT = [
  "Customizable tracking page",
  "Unlimited order tracking",
  "Priority support",
];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [usageNumber, setUsageNumber] = React.useState(0);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getUsage();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/track/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  function getUsage() {
    fetch(BASE_URL + "/usage", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsageNumber(data.count || 0);
      })
      .catch(() => {});
  }

  const planId = (instanceData as any)?.instance?.availablePlans[0]?.name;

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=0087937e-bfcc-4b00-bad1-c3e417e8b7a2&redirectUrl=https://certifiedcode.wixsite.com/track/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"0087937e-bfcc-4b00-bad1-c3e417e8b7a2"}?appInstanceId=${(instanceData as any)["instance"]["instanceId"]
  }`

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "${CONTENT[index]}"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Let customers track order with ease"
                content="Easily create order tracking page to offer better customer experience"
                actions={
                  <Box direction="vertical" gap="SP2">
                    <CircularProgressBar
                      size="medium"
                      label={
                        planId === "unlimited"
                          ? "You have unlimited tracking credits"
                          : `You have ${
                              limitPlans[planId || "basic"] - usageNumber
                            } tracking credits remaining`
                      }
                      skin={isUpgraded ? "success" : "standard"}
                      showProgressIndication
                      value={
                        (usageNumber / limitPlans[planId || "basic"]) * 100
                      }
                    />
                    <Button
                      skin="premium"
                      prefixIcon={<Icons.PremiumFilled />}
                      onClick={() => {
                        window.open(upgradeUrl);
                      }}
                    >
                      {isUpgraded ? "Manage Subscription" : "Upgrade App"}
                    </Button>
                    <Button
                      prefixIcon={<Icons.GetStarted />}
                      as="a"
                      href="https://support.certifiedcode.us/en/articles/8678985-track-setup-guide"
                      target="_blank"
                    >
                      Setup Guide
                    </Button>
                    {isUpgraded && (
                      <Button
                        prefixIcon={<Icons.Reviews />}
                        skin="inverted"
                        as="a"
                        href="https://bridget.reviews.certifiedcode.us/0087937e-bfcc-4b00-bad1-c3e417e8b7a2"
                        target="_blank"
                      >
                        Write a review
                      </Button>
                    )}
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Track} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
